import React from "react";
import styled from "styled-components";
import Rolling from "./rolling";

const Index = () => {
  return (
    <Container>
      <Title>{`공모주, \n 쉽고 편리하게`}</Title>
      <ImgContainer>
        <CenterImg src="/img/Center-main.svg" />
        <Rolling />
      </ImgContainer>
    </Container>
  );
};

const Container = styled.div`
  height: 485px;
  padding: 72px 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 93px;
  position: relative;
  width: 100%;
`;

const Title = styled.div`
  white-space: pre-line;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  line-height: 100%; /* 40px */
`;

const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  bottom: 72px;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;

`;

const CenterImg = styled.img`
  width: 144px;
  /* bottom: 72px; */
  position: absolute;
  z-index: 150;
`;
export default Index;
