import React from "react";
import styled from "styled-components";

const FooterInLogo = () => {
  return (
    <Container>
      <Logo src={"/img/main/ML_logo3.svg"} />
      <Bottom>
        <Name>엠엘투자자문(주)</Name>
        <Address>{`서울특별시 강남구 역삼로17길 10 \n 부강빌딩 3층`}</Address>
      </Bottom>
    </Container>
  );
};

const Container = styled.div`
  padding: 16px 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-top: 1px solid #000000;
`;

const Logo = styled.img`
  width: 172px;
  height: 20px;
`;

const Name = styled.div`
  font-size: 16px;
  font-style: normal;
`;

const Address = styled.div`
  white-space: pre-line;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export default FooterInLogo;
