import React from "react";
import styled from "styled-components";

const content07 = () => {
  return (
    <Container>
      <TitleContainer>
        <Desc1>재투자율</Desc1>
        <Desc2>61.45%</Desc2>
      </TitleContainer>
      <div>
        <Desc3>리텐션 50.2%</Desc3>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  width: 148px;
  height: auto;
  padding: 16px;
  box-sizing: border-box;
  background-image: linear-gradient(180deg, #4ab7ff 0%, #a7dcff 100%);
  color: #fff;
  gap: 16px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Desc1 = styled.div`
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  color: #fff;
`;
const Desc2 = styled.div`
  color: #FFF;
  font-family: Pretendard;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`;

const Desc3 = styled(Desc1)`
  color: #FFF;
  text-align: right;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;

export default content07;
