import React from 'react'
import Content02 from './Content02'
import Content03 from './Content03'
import Content04 from './Content04'
import Content05 from './Content05'
import Content06 from './Content06'
import Content07 from './Content07'
import Content01 from './Content01'
import styled from 'styled-components'
import StartButton from './StartButton'
import Note from './Note'
import DownloadApp from '@components/NewMain/DownloadApp'

const Index = () => {
  return (
    <Container>
      <ImgContainer>
        <First>
          <Content01 />
          <Content04 />
        </First>
        <Second>
          <Content02 />
          <Content05 />
        </Second>
        <Third>
          <Content03 />
          <Content06 />
          <Content07 />
        </Third>
      </ImgContainer>
      <ButtonContainer>
        <LogoImg src={'/img/main/ML_logo.svg'} />
      </ButtonContainer>
      <Note />
      <InstaLogo>
        {/* <img src={'/img/main/insta.svg'} alt='' /> */}
      </InstaLogo>
      <DownloadApp />
    </Container>
  )
}

const InstaLogo = styled.div`
  padding: 16px 0px;
  display: flex;
  float: left;
  width: 1280px;
`

const LogoImg = styled.img`
  margin-bottom: 104px;
`

const Container = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`
const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 24px;
`
const First = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
const Second = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const Third = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const ButtonContainer = styled.div`
  padding: 72px 0px;
  box-sizing: border-box;
`

export default Index
