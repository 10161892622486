import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Title from '@components/NewMain/Title'
import Mobile from './mobile'
import Mobile_Samsung from './mobile_samsung'
import Circle2 from './circle2'

const Index = () => {
  const [activeIndex, setActiveIndex] = useState(-1)
  const isMobileVisible = activeIndex >= 3

  useEffect(() => {
    if (activeIndex < 3) {
      const timer = setTimeout(() => {
        setActiveIndex((prevIndex) => prevIndex + 1)
      }, 3000)

      return () => clearTimeout(timer)
    } else {
      const resetTimer = setTimeout(() => {
        setActiveIndex(-1)
      }, 10000)

      return () => clearTimeout(resetTimer)
    }
  }, [activeIndex])

  return (
    <Container>
      <TitleContainer>
        <Title title={`잊고 살아요 \n 우리가 챙길게요`} />
      </TitleContainer>
      <CircleContainer>
        {/* <Circle2
          content={`일육공 \n 자동투자계약`}
          index={0}
          activeIndex={activeIndex}
          isMobileVisible={isMobileVisible}
        />
        <Circle2
          content={`증권계좌 \n 개설 · 연결`}
          index={1}
          activeIndex={activeIndex}
          isMobileVisible={isMobileVisible}
        /> */}
        {/* <Mobile /> */}
        <Mobile_Samsung />
        {/* {isMobileVisible && <Mobile />} */}
        {/* <Circle2
          content={`공모주 사고`}
          index={2}
          activeIndex={activeIndex}
          isMobileVisible={isMobileVisible}
        />
        <Circle2
          content={`공모주 팔고`}
          index={3}
          activeIndex={activeIndex}
          isMobileVisible={isMobileVisible}
        /> */}
      </CircleContainer>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100.375rem;
  background: linear-gradient(#f1f1f5, rgba(241, 241, 245, 0));
`

const CircleContainer = styled.div`
  display: flex;
  gap: 4.9375rem;
  height: 944px;
  overflow: hidden;
  justify-content: center;
  width: 100%;
  margin-top: 150px;
`

const TitleContainer = styled.div`
  margin-top: 50px;
`

export default Index
