import {
  SpoqaLight,
  SpoqaMedium,
  SpoqaRegular,
} from '@components/base/TextStyle/Spoqa'
import {
  BtnCalender,
  BtnFAQ,
  BtnScore,
  BtnWrapper,
  Container,
  LogoImage,
  LogoWrapper,
  MobileListMenu,
  MobileListTextWrapper,
  MobileMenuIcon,
  MobileMenuIconBtn,
} from './styles'
import { Colors } from '@styles/colors'
import Spacer from '@components/base/Spacer'
import useRouterHistoryHandler from '../../hooks/useRouterHistoryHandler'
import { useLocation } from 'react-router-dom'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import DownloadButton from '@components/downloadButton/DownloadButton'

function Header() {
  const currentPath = useLocation()?.pathname
  const [isVisible, setIsVisible] = useState(true)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const {
    handleGoFaqPage,
    handleGoCalendarPage,
    handleGoInfoPage,
    handleGoMainPage,
  } = useRouterHistoryHandler()
  const isMobile = useMediaQuery({ query: '(max-width: 700px)' })
  const logoSetting = {
    hover: {
      scale: 1.1,
    },
  }



  // useEffect(() => {
  //   const handleResize = () => {
  //     setWindowWidth(window.innerWidth);
  //   };

  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  // const isMobile = windowWidth <= 360;



  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(false)
      } else {
        setIsVisible(true)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <Container>
        <LogoWrapper>
          <LogoImage
            src={'/images/header/title.png'}
            alt='logo'
            onClick={() => handleGoMainPage()}
            variants={logoSetting}
            whileHover='hover'
          />
        </LogoWrapper>
        <MobileMenuIconBtn
          onClick={() => {
            setDrawerOpen(!drawerOpen)
          }}
        >
          <MobileMenuIcon
            src={
              drawerOpen
                ? 'images/mobile/menu-cancle.png'
                : 'images/mobile/menu-header.png'
            }
          />
        </MobileMenuIconBtn>
        <BtnWrapper>
          <BtnCalender
            onClick={() => handleGoCalendarPage()}
            currentPath={currentPath}
          >
            <SpoqaMedium size={18} color={Colors.g12}>
              일육공 캘린더
            </SpoqaMedium>
          </BtnCalender>
          <Spacer width={42} />
          <BtnScore
            onClick={() => handleGoInfoPage()}
            currentPath={currentPath}
          >
            <SpoqaMedium size={18} color={Colors.g12}>
              매력지수란
            </SpoqaMedium>
          </BtnScore>
          <Spacer width={42} />
          <BtnFAQ onClick={() => handleGoFaqPage()} currentPath={currentPath}>
            <SpoqaMedium size={18} color={Colors.g12}>
              FAQ
            </SpoqaMedium>
          </BtnFAQ>
          {window.location.href === 'http://localhost:3000/' ||
          window.location.href === 'https://160ipo.com/' ||
          window.location.href === 'https://160ipo.com/main-test' ||
          window.location.href === 'http://localhost:3000/main-test' ? (
            !isVisible && <DownloadButton />
          ) : (
            <DownloadButton />
          )}
        </BtnWrapper>
      </Container>
      <Drawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        direction='top'
        style={{
          marginTop: 48,
          boxShadow: '0px 0px 0px 0px',
          height: 187,
          borderWidth: 3,
          display: isMobile ? 'block' : 'none',
        }}
        enableOverlay={false}
      >
        <MobileListMenu>
          <MobileListTextWrapper
            onClick={() => {
              handleGoCalendarPage()
              setDrawerOpen(false)
            }}
          >
            <SpoqaRegular size={14} color={Colors.g12}>
              일육공 캘린더
            </SpoqaRegular>
          </MobileListTextWrapper>
          <MobileListTextWrapper
            onClick={() => {
              handleGoInfoPage()
              setDrawerOpen(false)
            }}
          >
            <SpoqaRegular size={14} color={Colors.g12}>
              매력지수란
            </SpoqaRegular>
          </MobileListTextWrapper>
          <MobileListTextWrapper
            onClick={() => {
              handleGoFaqPage()
              setDrawerOpen(false)
            }}
          >
            <SpoqaRegular size={14} color={Colors.g12}>
              FAQ
            </SpoqaRegular>
          </MobileListTextWrapper>
        </MobileListMenu>
      </Drawer>
      {/* mobileMenu */}{' '}
    </>
  )
}

export default Header
