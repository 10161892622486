import React, { useEffect } from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

const Content07 = () => {
  return (
    <Container>
      <Title>재투자율</Title>
      <Count>61.45%</Count>
      <Desc>리텐션 50.2%</Desc>
    </Container>
  );
};

const Container = styled.div`
  grid-area: box7;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
  border-radius: 32px;
  box-sizing: border-box;
  width: 397.5px;
  height: 264px;
  background-image: linear-gradient(180deg, #4ab7ff 0%, #a7dcff 100%);
`;

const Title = styled.div`
  color: #fff;
  font-size: 24px;
  font-style: normal;
  line-height: 120%;
`;

const Count = styled.div`
  font-size: 80px;
  font-style: normal;
  line-height: 100%;
  color: #fff;
`;

const Desc = styled.div`
  white-space: pre-line;
  color: #fff;
  font-size: 24px;
  font-style: normal;
  line-height: 120%;
`;
export default Content07;
