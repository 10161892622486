import React, { useEffect } from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

const CircleMotion = () => {
  useEffect(() => {
    AOS
      .init
      // {
      //     duration: 1000,
      //     easing: 'ease-out'
      // }
      ();
  }, []);

  return (
    <Container>
      <Circle_han
        src={"/img/main/circle/icon01.svg"}
        alt="한국투자증권"
        data-aos="zoom-out"
        data-aos-duration="2000"
      />
      <Circle_sam
        src={"/img/main/circle/icon02.svg"}
        alt="삼성증권"
        data-aos="zoom-out"
        data-aos-duration="1000"
      />
      <Circle_kb
        src={"/img/main/circle/icon05.svg"}
        alt="국민증권"
        data-aos="zoom-out"
        data-aos-duration="2000"
      />
      <Circle_nh
        src={"/img/main/circle/icon03.svg"}
        alt="농협"
        data-aos="zoom-out"
        data-aos-duration="3000"
      />
      <Circle_hana
        src={"/img/main/circle/icon04.svg"}
        alt=""
        data-aos="zoom-out"
        data-aos-duration="5000"
      />
      <CircleBlur_dae
        src={"/img/main/circle/icon06.svg"}
        alt=""
        data-aos="zoom-out"
        data-aos-duration="2000"
      />
      <CircleBlur_hi
        src={"/img/main/circle/icon07.svg"}
        alt="하이증권"
        data-aos="zoom-out"
        data-aos-duration="3000"
      />
      <CircleBlur_san
        src={"/img/main/circle/icon08.svg"}
        alt="산영투자"
        data-aos="zoom-out"
        data-aos-duration="4000"
      />
      <CircleBlur_mirae
        src={"/img/main/circle/icon10.svg"}
        alt="미래에셋"
        data-aos="zoom-out"
        data-aos-duration="1000"
      />
      <CircleBlur_kyobo
        src={"/img/main/circle/icon09.svg"}
        alt="교보증권"
        data-aos="zoom-out"
        data-aos-duration="4000"
      />
    </Container>
  );
};

const Container = styled.div``;

const Circle_han = styled.img`
  width: 246px;
  height: 246px;
  top: 262px;
  right: 372px;
  position: absolute;
`;

const Circle_sam = styled.img`
  width: 256px;
  height: 256px;
  top: 129px;
  left: 491px;
  position: absolute;
`;

const Circle_kb = styled.img`
  width: 215px;
  height: 215px;
  bottom: 515px;
  right: 80px;
  position: absolute;
`;

const Circle_nh = styled.img`
  width: 217px;
  height: 217px;
  bottom: 157px;
  left: 276px;
  position: absolute;
`;

const Circle_hana = styled.img`
  width: 184px;
  height: 184px;
  bottom: 244px;
  left: 7px;
  position: absolute;
`;

const CircleBlur_dae = styled.img`
  width: 223px;
  height: 223px;
  top: 505px;
  left: 172px;
  position: absolute;
`;

const CircleBlur_hi = styled.img`
  width: 128px;
  height: 128px;
  top: 243px;
  left: 63px;
  position: absolute;
`;
const CircleBlur_san = styled.img`
  width: 128px;
  height: 128px;
  bottom: 300px;
  right: 714px;
  position: absolute;
`;

const CircleBlur_mirae = styled.img`
  width: 192px;
  height: 182px;
  bottom: 204px;
  right: 239px;
  position: absolute;
`;

const CircleBlur_kyobo = styled.img`
  width: 128px;
  height: 128px;
  bottom: 353px;
  right: 48px;
  position: absolute;
`;

export default CircleMotion;
