import React from 'react'
import styled from 'styled-components'
import PlayButton from './playButton'
import PlayingButton from './playingButton'

type MenuProps = {
  title: string
  base?: string
  detail?: string
  isVisible: boolean
  toggleVisibility: () => void
  isLast: boolean
  seekToTime?: (seconds: number) => void
  time?: number
}

const Menu = ({
  title,
  base,
  detail,
  isVisible,
  toggleVisibility,
  isLast,
  seekToTime,
  time,
}: MenuProps) => {
  const handleClick = () => {
    if (seekToTime && time !== undefined) {
      seekToTime(time)
    }
    toggleVisibility()
  }

  return (
    <Container onClick={handleClick} isLast={isLast}>
      <Title>
        {title}
        {isVisible ? <PlayingButton /> : <PlayButton />}
      </Title>
      {isVisible && (
        <Content>
          {base}
          <Detail>{detail}</Detail>
        </Content>
      )}
    </Container>
  )
}

const Container = styled.div<{ isLast: boolean }>`
  padding: 16px 24px;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  justify-content: center;
  border-bottom: ${(props) => (props.isLast ? 'none' : '1px solid #eee')};
`

const Title = styled.div`
  color: #121212;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: -0.33px;
  display: flex;
  justify-content: space-between;
  font-family: 'Spoqa Han Sans Neo', sans-serif;
`

const Content = styled.div`
  font-size: 16px;
  font-style: normal;
  line-height: 150%;
  letter-spacing: -0.33px;
  color: #555;
  margin-top: 16px;
  white-space: pre-line;
  font-family: 'Spoqa Han Sans Neo', sans-serif;
`

const Detail = styled.div`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.333px;
  color: #999;
  margin-top: 8px;
  white-space: pre-line;
  font-family: 'Spoqa Han Sans Neo', sans-serif;
`

export default Menu
