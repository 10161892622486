import React from "react";
import styled from "styled-components";

const CopyRight = () => {
  return (
    <Container>
      <Insta src={"/img/main/insta.svg"} />
      <Content>
        {`ⓒ ML Investment Advisionary Co.,Ltd. \n All Rights Reserved.`}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-line;
  color: #000;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: Pretendard;

`;
const Insta = styled.img``;

export default CopyRight;
