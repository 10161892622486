import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";


const Index = () => {
  return (
    <Container>
        <Section1>
        <CrossBrand>일육공 X 삼성증권</CrossBrand>
        <Title>{`공모주 간편투자 제휴 이벤트`}</Title>
        <Content>{`공모주 투자일임계약하고 신세계 \n 모바일 상품권 최대 4만원 받으세요.`}
        <Content2>(미션 완료 시 · 선착순 5,000명)</Content2>
        <BorderBox />
        </Content>
        <EventTarget>
              <ContentTitle>대상</ContentTitle>
              <Detail>
              일육공에서 삼성증권 계좌개설 후 간편 투자 이용 고객
              </Detail>
            </EventTarget>
            <EventTerm>
              <ContentTitle>기간</ContentTitle>
              <Detail>2024년 9월 27일 ~ 2024년 12월 31일</Detail>
            </EventTerm>
            <GiftCard src={'/img/samsung/gift-card-heart.svg'}></GiftCard>
            <Link to="/samsung-event">
              <EventButton>이벤트 자세히 보기</EventButton>
            </Link>
            <Desc>{`※ 투자전 설명 청취 및 상품설명서 필독 \n ※ 이 금융상품은 예금자보호법에 따라 보호되지 않습니다. \n ※ 자산가격변동 등에 따른 원금손실(0~100%) 발생가능 및 투자자 귀속 \n ※ 청약의 권유는 (예비·간이)투자설명서에 따름 \n 
한국금융투자협회 심사필 제24-03913호 \n (2024-09-30 ~ 2025-09-29)`}</Desc>
      </Section1>
    
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 360px;
  background-color: #0E6DFB;
  padding: 72px 24px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
`;

const ContentTitle = styled.div`
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

const Detail = styled.div`
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  line-height: 150%;
`


const EventTarget = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
`
const EventTerm = styled(EventTarget)``


const Section1 = styled.div`
  height: auto;
  /* padding: 48px 24px; */
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  white-space: pre-line;
  color: white;
  background-color: #0e6dfb;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 32px;
`

const Content = styled.div`
  color: rgba(255, 255, 255, 0.90);
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 21.6px */
  letter-spacing: -0.36px;
`

const Mission = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  border-bottom: 1px solid #c5ccd6;
  padding: 16px 0px;
  box-sizing: border-box;

  &:last-child {
    border-bottom: none;
  }
`
const Title = styled.div``

const Section2 = styled.div`
  padding: 64px 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 56px;
`

const SubTitle = styled.div`
  color: #0e6dfb;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

const SubContent = styled.div`
  color: #555564;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const Period = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Participation = styled.div``
const GiftCard = styled.img`
  width: 100%;
`

const MissionContainer = styled.div`
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
`

const MissionNumber = styled.div`
  color: var(--brand-color-2-0, #0e6dfb);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  padding: 4px 4px;
  border-radius: 4px;
  border: 1px solid #bae6fd;
  width: auto;
  justify-content: center;
  align-items: center;
  background-color: #e0f2fe;
  height: 27px;
`
const MissionContent = styled.div`
  color: var(--ui-color-text-dark, #555564);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 257px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Favor = styled.div`
  border-radius: 16px;
  width: 100%;
  height: auto;
  padding: 16px 16px;
  background-image: linear-gradient(159deg, #f0f9ff -1.66%, #5e9fff 126.18%);
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`

const Top = styled.div`
  color: #0e6dfb;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Circle = styled.div`
  width: 88px;
  height: 88px;
  border-radius: 50%;
  background-color: #0e6dfb;

  color: #fff;
  font-family: Pretendard;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Bottom = styled.div`
  border-radius: 8px;
  border: 1px solid #d2eafa;
  padding: 16px 12px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
`

const Guide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
`

const GuideContent = styled.div<{ isFirst: boolean }>`
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: ${(props) => (props.isFirst ? '#6f6e6e' : '#0E6DFB')};
`

const Section3 = styled.div`
  padding: 24px 16px 72px 16px;
  display: flex;
  flex-direction: column;
  background-color: #dbdfe9;
  gap: 13px;
  color: #808491;
  box-sizing: border-box;
`

const Dot = styled.div`
  padding: 0px 4px;
  box-sizing: border-box;
`

const NoticeTitle = styled.div`
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`

const ContentContainer = styled.div`
  display: flex;
`

const Notice = styled.div`
  display: flex;
  flex-direction: column;
`

const NoticeContent = styled.div`
  color: #808491;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`

const HI = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`

const CrossBrand = styled.div`
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  color: #fff;
`

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 146px;
  gap: 4px;
  color: #0e6dfb;
`
const BottomTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
const BottomContent = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: pre-line;
`

const Sticker = styled.div`
  display: flex;
  border-radius: 8px;
  background-color: #e0f2fe;
  padding: 12px 16px;
  width: auto;
  color: #0e6dfb;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
  width: 126.73px;
`

const SubTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const FCFS = styled.div`
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #999ba9;
`

const EventButton = styled.div`
  border-radius: 16px;
  color: #0E6DFB;
  background-color: #fff;
  width: 100%;
  padding: 16px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  line-height: 150%;
  `

const Desc = styled.div`
  white-space: pre-line;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 112.5% */
  letter-spacing: -0.33px;
  margin-top: 72px;
  opacity: 0.7;
`

const Content2 = styled.div`
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  line-height: 150%; 
  opacity: 0.8;
  margin-top: 4px;
`


const BorderBox = styled.div`
  width: 100%;
  height: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #fff;
`

export default Index;
