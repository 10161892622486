import React, { useEffect } from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import CircleMotion from "./circleMotion";

const Index = () => {
  useEffect(() => {
    AOS.init({
      duration: 2500,
      easing: "ease-out"
    });
  }, []);

  return (
    <Container>
      <Title>
      <Sparkles src={'/img/Sparkles.svg'} alt="Sparkles" />
        {`여러 증권사의 공모주를 \n 검증된 AI로 \n 우량 종목만 선별하여`}
      </Title>
      <CircleMotion />
    </Container>
  );
};

export default Index;

const Sparkles = styled.img`
  z-index:20px;
  height: 88px;
  position: absolute;
  top: 110.5px;
  left: -31px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  /* min-height: 1414px; */
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #f5f6f8;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  white-space: pre-line;
  z-index: 100;
  color: #000;
  text-align: center;
  font-size: 72px;
  font-style: normal;
  line-height: normal;
  position: relative;
`;
