import React from "react";
import styled from "styled-components";

const content06 = () => {
  return (
    <Container>
      <Desc1>투자 고객</Desc1>
      <Desc2>1,000+</Desc2>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  width: 148px;
  height: 140px;
  padding: 16px;
  box-sizing: border-box;
  background-image: linear-gradient(128deg, #ff00a1 46.01%, #cb258e 103.39%);
  color: #fff;
`;

const Desc1 = styled.div`
  color: #FFF;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;
const Desc2 = styled.div`
  color: #FFF;
  font-family: Pretendard;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`;
export default content06;
