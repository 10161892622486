import { ReactElement, useEffect, useState } from 'react'
import { Switch } from 'react-router-dom'
import styled from 'styled-components'
import Header from './components/Header'
import routerItems from './router/paths'
import RouterRenderer from './router/routerRender'
import Footer from '@components/Footer'
import { Colors } from '@styles/colors'
import ChannelTalkManager from '@components/ChannelTalkManager'
import FloatBtn from '@components/base/FloatBtn'
import MobileHeader from "@components/NewMain/Mobile/Header"

const Wrapper = styled.div`
  background-color: ${Colors.gf};
  min-height: 100vh;
`



function App(): ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const isEventPage = window.location.href.includes('kb-event') ? true : false
  const applinkPage =
    window.location.href.includes('app-link-bridge') ||
    window.location.href.includes('welcome')
      ? true
      : false

  const inquirePage = window.location.href.includes('inquire') ? true : false
  const newMainPage = window.location.href.includes('new-main')
  const guidePage = window.location.href.includes('guide')
  const samsungEventPage = window.location.href.includes('samsung-event')
  const mobileMainPage = window.location.href.includes("mobile-main")
  const tabletMainPage = window.location.href.includes("tablet-main")




  return (
    <>
    <Wrapper>
      {!isEventPage &&
        !applinkPage &&
        !inquirePage &&
        !newMainPage &&
        !guidePage &&
        !samsungEventPage &&
        !mobileMainPage &&
        !tabletMainPage && <Header />}
      {!guidePage && !samsungEventPage && !newMainPage && !mobileMainPage && !tabletMainPage && (
        <FloatBtn isOpen={isOpen} setIsOpen={setIsOpen} isShowDownBtn={true} />
      )}
      <ChannelTalkManager />
      <Switch children={RouterRenderer(routerItems)} />
      {!isEventPage &&
        !applinkPage &&
        !inquirePage &&
        !guidePage &&
        !newMainPage &&
        !mobileMainPage &&
        !tabletMainPage &&
        !samsungEventPage && <Footer />}
    </Wrapper>
    </>
  )
}

export default App
