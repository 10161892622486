import React, { useEffect } from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

const Content04 = () => {
  return (
    <Container>
      <Title>{`일육공 하나로 \n 끝나는 \n 공모주 간편투자`}</Title>
    </Container>
  );
};

const Container = styled.div`
  grid-area: box6;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
  border-radius: 32px;
  box-sizing: border-box;
  width: 437px;
  height: 393px;
  background-image: url("/img/main/contentBg01.svg");
  background-size: cover;
`;

const Title = styled.div`
  color: black;
  font-size: 40px;
  font-style: normal;
  line-height: 120%;
  white-space: pre-line;
  text-align: center;
`;
export default Content04;
