import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import FooterInLogo from "./FooterInLogo";

type IsOpenProps = {
  isOpen?: boolean;
  onClose?: () => void;
};

const Menu = ({ isOpen, onClose }: IsOpenProps) => {
  const [isSectionOpen, setIsSectionOpen] = useState(false);

  React.useEffect(() => {
    if (isOpen) {
      setIsSectionOpen(true);
    } else {
      setIsSectionOpen(false);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <Container>
      <Header>
        <XIcon src={"/img/X.svg"} onClick={onClose} />
      </Header>
      <Content>
        <AnimatedSection isSectionOpen={isSectionOpen}>
          <Title>{`공모주,\n쉽고 편리하게`}</Title>
          <Download onClick={() => window.open("https://160ipo.com/inquire")}>앱 다운로드</Download>
          <MenuItem>
            <Name onClick={() => window.open("https://160ipo.com/inquire")}>1:1 문의</Name>
            <Desc>{`평일 (오전 9시 ~ 오후 6시)\n점심시간 (오전 11시30분 ~ 오후 1시)`}</Desc>
          </MenuItem>
          {/* <MenuItem>
            <Name onClick={() => window.open("https://www.ml-invest.co.kr/")}>
              메타로고스투자자문↗
            </Name>
          </MenuItem>
          <MenuItem>
            <Name>인스타그램</Name>
          </MenuItem> */}
        </AnimatedSection>
        <NavigateSection>
          <Navigate>개인정보처리방침</Navigate>
          <Navigate>서비스이용약관</Navigate>
        </NavigateSection>
        <FooterInLogo />
      </Content>
    </Container>
  );
};


const Download = styled.div`
  display: flex;
  border-radius: 8px;
  background-color: #0e6dfb;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  line-height: normal;
  font-family: Pretendard;
  padding: 16px 8px;
  box-sizing: border-box;
  

  `


const dropdownAnimation = keyframes`
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 539px; 
    opacity: 10;
  }
`;

const Container = styled.div`
  width: 100%;
  z-index: 500;
  background-color: #fff;
  position: absolute;
  top: 0px;
  left: 0px;
  min-width: 360px;
  height: 100vh;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  height: 79px;
  border-bottom: 1px solid #c9d3de;
  padding: 16px;
  box-sizing: border-box;
`;

const XIcon = styled.img`
  width: 24px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const AnimatedSection = styled.div<{ isSectionOpen: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 24px;
  box-sizing: border-box;
  gap: 24px;
  height: ${(props) => (props.isSectionOpen ? "539px" : "0")};
  overflow: hidden;
  opacity: ${(props) => (props.isSectionOpen ? 1 : 0)};
  transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  animation: ${(props) => (props.isSectionOpen ? dropdownAnimation : "none")}
    0.5s forwards;
`;

const Title = styled.div`
  font-family: Pretendard;
  font-size: 28px;
  font-style: normal;
  line-height: 100%;
  white-space: pre-line;
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.div`
  color: #000;
  font-size: 24px;
  font-style: normal;
  cursor: pointer;
`;

const Desc = styled.div`
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  line-height: 120%;
  color: #81828a;
`;

const NavigateSection = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px;
  box-sizing: border-box;
  font-size: 16px;
  font-style: normal;

  line-height: normal;
`;

const Navigate = styled.div``;

export default Menu;
