import React from "react";
import styled from "styled-components";
import Content01 from "./content01";
import Content02 from "./content02";
import Content03 from "./content03";
import Content04 from "./content04";
import Content05 from "./content05";
import Content06 from "./content06";
import Content07 from "./content07";

const Index = () => {
  return (
    <Container>
      <Content01 />
      <Content02 />
      <Content03 />
      <Content04 />
      <Content05 />
      <Content06 />
      <Content07 />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  max-width: 360px;
  /* background-color: red; */
  justify-content: center;
`;

export default Index;
