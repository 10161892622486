import React from "react";
import styled from "styled-components";

const content02 = () => {
  return <Container>{`일육공 하나로 \n 끝나는 \n 공모주 간편투자`}</Container>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 16px;
  width: 148px;
  height: auto;
  padding: 16px;
  box-sizing: border-box;
  background-image: url("/img/main/contentBg01.svg");
  background-size: cover;
  white-space: pre-line;
  color: #000;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;


`;
export default content02;
