import React, {useEffect} from 'react'
import AOS from "aos";
import "aos/dist/aos.css";

import styled from 'styled-components'

type TitleProps = {
  title: string
}

const Title = ({ title }: TitleProps) => {
  useEffect(() => {
    AOS.init();
  }, []);


  return <Container data-aos="fade-down">{title}</Container>
}

const Container = styled.p`
  display: flex;
  text-align: center;
  font-size: 72px;
  font-style: normal;
  line-height: normal;
  white-space: pre-line;
`

export default Title
