import React, { useEffect, useState, useRef } from 'react'
import Card from './card'
import CardInParty from './cardInParty'

import styled from 'styled-components'
import AOS from 'aos'
import 'aos/dist/aos.css'
import CountUp from './countUp'

const Index = () => {
  const [isCardVisible, setIsCardVisible] = useState(false)
  const thirdCardRef = useRef(null)
  const countUpRef = useRef(null)
  const [countUpStarted, setCountUpStarted] = useState(false)
  const [cardsAnimated, setCardsAnimated] = useState({
    first: false,
    second: false,
    third: false,
  })

  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    })
  }, [])

  useEffect(() => {
    const handleAOSAnimation = (e: any) => {
      const { detail } = e
      if (detail.classList.contains('first-card')) {
        setCardsAnimated((prev) => ({ ...prev, first: true }))
      }
      if (detail.classList.contains('second-card')) {
        setCardsAnimated((prev) => ({ ...prev, second: true }))
      }
      if (detail.classList.contains('third-card')) {
        setCardsAnimated((prev) => ({ ...prev, third: true }))
      }
    }

    document.addEventListener('aos:in', handleAOSAnimation)

    return () => {
      document.removeEventListener('aos:in', handleAOSAnimation)
    }
  }, [])

  useEffect(() => {
    if (cardsAnimated.first && cardsAnimated.second && cardsAnimated.third) {
      setCountUpStarted(true)
    }
  }, [cardsAnimated])

  useEffect(() => {
    if (countUpStarted) {
      console.log('countUpStarted', countUpStarted)
    }
  }, [countUpStarted])

  return (
    <Container>
      <Card
        fadeRight
        className='first-card'
        title='청약이 내일 시작됩니다.'
        content='공모가: 60,000원'
        time='2시간 전'
        width={507}
        top={112}
        left={165}
      />
      <Card
        fadeLeft
        className='second-card'
        title='청약을 위한 투자금이 부족합니다.'
        content='OO증권계좌에 **,000원 입금해주세요.'
        time='30분 전'
        width={507}
        right={272}
        top={200}
      />
      <Card
        fadeRight
        className='third-card'
        title='공모주 매도 완료'
        content={`오늘 공모주 매도했습니다. \n 매도가 ***,000원`}
        time='방금'
        width={507}
        left={249}
        bottom={140}
      />
      <Title data-aos='fade-down' ref={countUpRef}>
        <CountUp />
        {/* {countUpStarted && <CountUp />} */}
        <div>{`청약부터 매도까지 \n 알아서 다 해드리는`}</div>
        <div>{`일육공 간편투자서비스`}</div>
        <div>{`수익 안 나면 \n 수수료 안 받아요`}</div>
      </Title>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 72px;
  font-style: normal;
  white-space: pre-line;
  gap: 72px;
  position: relative;

  text-align: center;

  font-size: 72px;
  font-style: normal;
  line-height: normal;

`

export default Index
