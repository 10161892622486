import React from "react";
import styled from "styled-components";
import CopyRight from "./copyRight";

const Index = () => {
  return (
    <Container>
      <CopyRight />
      <AppDownload onClick={() => window.open("https://qr-efbc6.web.app/")}>
        앱 다운로드
      </AppDownload>
    </Container>
  );
};

const Container = styled.div`
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const AppDownload = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #0E6DFB;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  border-radius: 18px;
  padding: 16px 24px;
  box-sizing: border-box;
  width: 100%;
  min-width: 312px;

`

export default Index;
