import React, { useEffect } from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

const Index = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-out"
    });
  }, []);
  return (
    <Container>
      <Title>{`잊고 살아요 \n 우리가 챙길게요`}</Title>
      <Phone src={"/img/samsung/samsung_mobile_img.svg"} data-aos="slide-up" />
    </Container>
  );
};

const Container = styled.div`
  height: 749px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-image: linear-gradient(
    180deg,
    #f1f1f5 0%,
    rgba(241, 241, 245, 0) 100%
  );

  padding: 65px 20px 0px 20px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
`;

const Phone = styled.img`
  width: 320px;
  bottom: 0px;
  position: absolute;
`;

const Title = styled.div`
  white-space: pre-line;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  line-height: normal;
`;

export default Index;
