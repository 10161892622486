import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Section01 from './section01/index'
import Section02 from './section02/index'
import Section03 from './section03/index'
import Section04 from './section04/index'
import Section05 from './section05/index'
import Header from '@components/NewMain/Header'
import FooterInLogo from '@components/NewMain/Footer'
import Samsung from './samsung/index'
import MobileHeader from "@components/NewMain/Mobile/Header";
import MobilePage from "../NewMobilePage/index"

const NewMainPage = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkDeviceType = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const mobileDevices = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;

      setIsMobile(mobileDevices.test(userAgent));
    };

    checkDeviceType();
  }, []);

  return (
    <Container>
      {isMobile ? <MobileHeader /> : <Header />}
      {/* <MobileHeader /> */}
      {isMobile ? <MobilePage /> : (
        <>
          <Section01 />
          <Section02 />
          <Section03 />
          <Samsung />
          <Section04 />
          <Section05 />
          <FooterInLogo />
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  scroll-behavior: auto;
  width: 100%;
`

export default NewMainPage;
