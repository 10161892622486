import React, { useState, useEffect } from "react";
import styled from "styled-components";

const CountUp = ({ ref }: any) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let interval: any;
    if (count < 12) {
      interval = setInterval(() => {
        setCount((prevCount) => prevCount + 1);
      }, 50);
    } else {
      setTimeout(() => {
        setCount(0);
      }, 5000);
    }

    return () => clearInterval(interval);
  }, [count]);

  return <Container ref={ref}>
    <div>{count}
    </div>
    </Container>;
};

const Container = styled.div`
  color: #fff;
  font-size: 40px;
  font-weight: 900;
  border-radius: 72px;
  border: 2px rgba(201, 0, 0, 0.9) solid;
  background: rgba(255, 0, 0, 0.9);
  position: absolute;
  top: -35px;
  right: 2px;

  width: 80px;
  height: 64px;
  padding: 12px 12px;
  box-sizing: border-box;

  color: #fff;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
`;

export default CountUp;
