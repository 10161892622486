import React from "react";
import styled from "styled-components";

const Download = () => {
  return (
    <Container>
      <div onClick={() => window.open('https://qr-efbc6.web.app/')}> 
        앱 다운로드
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  border-radius: 8px;
  padding: 16px 8px;
  height: 35px;
  background-color: #0e6dfb;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  font-family: Pretendard;
  justify-content: center;
  align-items: center;

`;

export default Download;
