import React, { forwardRef } from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

type CardProps = {
  title: string;
  content: string;
  time: string;
  img?: string;
  width: number;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  fadeRight?: boolean;
  fadeLeft?: boolean;
  ref?: any;
  className?: string;
  height: number;
};

const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      title,
      content,
      time,
      width,
      top,
      bottom,
      left,
      right,
      fadeRight,
      fadeLeft,
      className
    },
    ref
  ) => {
    return (
      <>
        <Container
          data-aos={
            fadeRight ? "fade-right" : fadeLeft ? "fade-left" : "fade-default"
          }
          width={width}
          left={left}
          top={top}
          right={right}
          bottom={bottom}
          ref={ref}
        >
          <CardDiv>
            <LogoImg>
              <img
                src="/img/main/160_logo2.svg"
                width="54px"
                height="54px"
                alt="logo"
              />
            </LogoImg>
            <Text>
              <Left>
                <Title>{title}</Title>
                <Content>{content}</Content>
              </Left>
              <Right>
                <Time>{time}</Time>
              </Right>
            </Text>
          </CardDiv>
        </Container>
      </>
    );
  }
);

const CardDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  /* height: 100%; */
`;

const Container = styled.div<{
  width: number;
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
}>`
  display: flex;
  border-radius: 19.3px;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 16px 18px 0px rgba(0, 0, 0, 0.25);
  padding: 16.5px 12.4px 18.5px 13.1px;
  box-sizing: border-box;
  width: ${(props) => props.width}px;

  /* height: 128px; */
  z-index: 100;
  position: absolute;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  right: ${(props) => props.right}px;
  bottom: ${(props) => props.bottom}px;
`;

const LogoImg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  margin-right: 10.63px;
`;

const Text = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.div`
  color: #FFF;
  font-family: Pretendard;
  font-size: 13.75px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.275px;
`;

const Time = styled.div`
  color: rgba(255, 255, 255, 0.80);
  text-align: right;
  font-family: Pretendard;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 12.375px; /* 112.5% */
  letter-spacing: -0.22px;
  opacity: 0.5;
`;

const Content = styled.div`
  color: rgba(255, 255, 255, 0.80);
  font-family: Pretendard;
  font-size: 13.75px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: pre-line;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;


const Right = styled.div``;

export default Card;