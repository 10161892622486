import React, { useState } from "react";
import styled from "styled-components";
import Download from "../Download";
import Menu from "./Menu";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    console.log("isOpen", isOpen);
    setIsOpen(!isOpen);
  };

  return (
    <Container>
      <Logo src={"/img/160_logo.svg"} alt="logo" />
      <RightContainer>
        <Download />
        <MenuIcon src={"/img/hamburger.svg"} alt="menu" onClick={toggleMenu} />
      </RightContainer>
      {isOpen && <Menu isOpen={isOpen} onClose={toggleMenu} />}
    </Container>
  );
};

const Container = styled.div`
  background-color: #fff;
  height: 78px;
  padding: 16px 24px;
  box-sizing: border-box;
  justify-content: space-between;
  border-bottom: 1px solid #c9d3de;
  width: 100%;
  display: flex;
  align-items: center;
  min-width: 360px;
`;

const Logo = styled.img``;
const RightContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const MenuIcon = styled.img``;
export default Header;
