import React from "react";
import styled from "styled-components";

const Index = () => {
  return (
    <Container>
      <FooterDiv>
        <CorporateInfoLeft>
          <Logo src={"/img/main/ML_logo2.svg"} />
          <Info>
            <Name>엠엘투자자문(주)</Name>
            <Address>{`서울특별시 강남구 역삼로17길 10 \n 부강빌딩 3층`}</Address>
          </Info>
        </CorporateInfoLeft>
        <CorporateInfoRight>
          <Title>고객지원</Title>
          <Email>hello@metalogos.co.kr ↗ </Email>
          <CallNumber>전화 02-6949-0045</CallNumber>
          <OperatingHour>평일 (10:00 - 17:00)</OperatingHour>
        </CorporateInfoRight>
      </FooterDiv>
      <CopyRightContainer>
        <CopyRight>
          <LeftText>
            ⓒ ML Investment Advisionary Co.,Ltd. All Rights Reserved.
          </LeftText>
          <RightContainer>
            <Navigate>개인정보처리방침</Navigate>
            <Navigate>서비스이용약관</Navigate>
          </RightContainer>
        </CopyRight>
      </CopyRightContainer>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: 221px;
  background-color: #fff;
  border-top: 1px solid #000;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CorporateInfoLeft = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-line;
  gap: 8px;
`;

const CorporateInfoRight = styled(CorporateInfoLeft)`
  text-align: right;
`;

const Logo = styled.img`
  margin-bottom: 8px;
`;

const Address = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Title = styled.div`
  font-size: 16px;
  font-style: normal;
  line-height: 120%;
`;

const Name = styled.div`
  font-size: 18px;
`;


const Email = styled.div`
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

const FooterDiv = styled.div`
  width: 1280px;
  display: flex;
  justify-content: space-between;
  padding: 24px 0px;
  box-sizing: border-box;
`;

const CopyRightContainer = styled.div`
  width: 100%;
  border-top: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CopyRight = styled.div`
  width: 1280px;
  height: 67px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CallNumber = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;
const OperatingHour = styled.div`
  color: #81828a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;
const LeftText = styled.div``;
const RightContainer = styled.div`
  display: flex;
  gap: 16px;
`;
const Navigate = styled.div``;

export default Index;
